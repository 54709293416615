<template>
  <base-material-card color="primary">
    <template v-slot:heading>
      <div class="float-left display-2 font-weight-light">
        {{ category[lang] ? category[lang].name : "" }}
      </div>
      <div style="position: absolute; right: 30px; top: 25%">
        <v-btn light icon @click="open_modal_category(category)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn light icon @click="show_delete_dialog(category.id, null)">
          <v-icon class="mx-1">mdi-close</v-icon>
        </v-btn>
      </div>
    </template>

    <v-form>
      <v-container class="py-0">
        <v-simple-table>
          <thead>
            <tr>
              <th class="primary--text">{{ $t("name") }}</th>
              <th class="primary--text">{{ $t("description") }}</th>
              <th class="text-right primary--text">{{ $t("price") }}</th>
              <th class="primary--text"></th>
            </tr>
          </thead>

          <draggable
            v-model="categoryentries"
            group="categoryentries"
            @start="drag = true"
            @end="drag = false"
            tag="tbody"
          >
            <tr v-for="(item, index) in categoryentries" v-bind:key="index">
              <td>{{ item[lang] ? item[lang].name : "" }}</td>
              <td>{{ item[lang] ? item[lang].description : "" }}</td>
              <td class="text-right">
                <div v-if="item.price">
                  {{
                    parseFloat(item.price)
                      .toFixed(2)
                      .toString()
                      .replace(".", ",")
                  }}€
                </div>
              </td>
              <td
                class="text-right"
                style="display: flex; gap: 1rem; flex-direction: row-reverse"
              >
                <v-btn
                  light
                  icon
                  @click="open_modal_edit_entry(category.id, item, item.id)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  light
                  icon
                  @click="show_delete_dialog(category.id, item.id)"
                >
                  <v-icon class="mx-1">mdi-close</v-icon>
                </v-btn>
              </td>
            </tr>
          </draggable>
        </v-simple-table>
        <draggable
          v-model="subcategories"
          group="subcategories"
          @start="drag = true"
          @end="drag = false"
        >
          <v-row
            no-gutters
            v-for="subcategory in subcategories"
            v-bind:key="subcategory.id"
            style="padding: 30px"
          >
            <subcategory-component
              @open_modal_new_entry="open_modal_new_entry"
              @open_modal_edit_entry="open_modal_edit_entry"
              @open_modal_category="open_modal_category"
              :subcategory="subcategory"
              :lang="lang"
            ></subcategory-component>
          </v-row>
        </draggable>
        <v-col cols="12" class="text-right">
          <v-btn
            style="margin-right: 10px !important"
            color="primary"
            class="mr-0"
            @click="open_modal_category(null, category.id)"
            >{{ $t("newsubcategory") }}</v-btn
          >
          <v-btn
            color="primary"
            class="mr-0"
            @click="open_modal_new_entry(category.id)"
            >{{ $t("newentry") }}</v-btn
          >
        </v-col>
        <v-row justify="center">
          <delete-dialog
            @close-dialog="close_delete_dialog"
            @confirm-dialog="confirm_delete_dialog"
            :dialog="dialog"
            :categoryId="this.categoryId"
            :entryId="this.entryId"
          ></delete-dialog>
        </v-row>
      </v-container>
    </v-form>
  </base-material-card>
</template>

<script>
import draggable from "vuedraggable";

export default {
  props: ["category", "lang"],
  components: {
    "delete-dialog": () => import("../dialog/DeleteDialog"),
    "subcategory-component": () => import("./Subcategory.vue"),
    draggable,
  },
  data() {
    return {
      dialog: false,
      categoryId: null,
      entryId: null,
    };
  },
  computed: {
    subcategories: {
      get() {
        let cats = this.$store.state.categories.filter((cat) => {
          return cat.parentId === this.category.id;
        });

        return cats.sort((a, b) => {
          return a.index - b.index;
        });
      },
      set(value) {
        let cats = this.$store.state.categories
          .filter((cat) => {
            return cat.parentId !== this.category.id;
          })
          .concat(value);

        value.forEach((val_el, val_index) => {
          cats.forEach((cats_el) => {
            if (cats_el.id === val_el.id) {
              cats_el.index = val_index;
            }
          });
        });
        cats.forEach((el) => {
          this.$store.dispatch("UPDATE_CATEGORY", { category: el });
        });
      },
    },
    categoryentries: {
      get() {
        let entries = [];
        let entryDir = this.category.entries;
        if (entryDir) {
          Object.keys(entryDir).forEach(function (key) {
            entryDir[key]["id"] = key;
            entries.push(entryDir[key]);
          });
        }
        return entries.sort((a, b) => {
          return a.index - b.index;
        });
      },
      set(value) {
        value.forEach((el, index) => {
          for (let i in this.category.entries) {
            if (i == el.id) {
              this.category.entries[i].index = index;
              break;
            }
          }
        });
        this.$store.dispatch("UPDATE_CATEGORY", { category: this.category });
      },
    },
  },
  methods: {
    show_delete_dialog(categoryId, entryId) {
      this.categoryId = categoryId;
      this.entryId = entryId;
      this.dialog = true;
    },
    confirm_delete_dialog: function ({ categoryId, entryId }) {
      this.dialog = false;
      if (!entryId) {
        console.log("delete category " + categoryId);
        this.delete_category(categoryId);
      } else {
        console.log("delete entry " + categoryId + " " + entryId);
        this.delete_entry(categoryId, entryId);
      }
    },
    close_delete_dialog: function (event) {
      this.dialog = false;
    },
    delete_category: function (categoryId) {
      this.$store
        .dispatch("DELETE_CATEGORY", { categoryId: categoryId })
        .then(() => {
          this.$notify({
            group: "foo",
            title: this.$t("deleted"),
            text: this.$t("category_deleted"),
            type: "error",
          });
        });
    },
    delete_entry: function (categoryId, entryId) {
      this.$store
        .dispatch("DELETE_ENTRY", {
          categoryId: categoryId,
          entryId: entryId,
        })
        .then(() => {
          this.$notify({
            group: "foo",
            title: this.$t("deleted"),
            text: this.$t("entry_deleted"),
            type: "error",
          });
        });
    },
    open_modal_new_entry: function (categoryId) {
      this.$emit("open_modal_new_entry", { categoryId: categoryId });
    },
    open_modal_edit_entry: function (category, item, itemId) {
      // subcategory item
      if (!item && !itemId && category.item) {
        this.$emit("open_modal_edit_entry", {
          categoryId: category.categoryId,
          item: category.item,
          itemId: category.itemId,
        });
      }
      // category item
      else {
        this.$emit("open_modal_edit_entry", {
          categoryId: category,
          item: item,
          itemId: itemId,
        });
      }
    },
    open_modal_category: function (category, parentId) {
      // subcategory
      if (category && !parentId && category.parentId) {
        // subcategory
        this.$emit("open_modal_category", {
          category: category.category,
          parentId: category.parentId,
        });
      }
      //category
      else {
        this.$emit("open_modal_category", {
          category: category,
          parentId: parentId,
        });
      }
    },
  },
};
</script>
